<template>
    <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark" id="nav">
        <a class="navbar-brand" href="#">
            <img src="@/assets/logo-utm.png" /> <small>DEV 1.0.7</small>
        </a>
        <ul class="navbar-nav mr-auto">
            <li class="nav-item d-none d-lg-block">
                <a @click="refresh" class="btn btn-sm" style="color: white">Refresh</a>
            </li>
            <!-- <li class="nav-item active">
                <router-link to="/" class="nav-link">Home</router-link>
            </li> -->
            <!-- <li class="nav-item">
                <router-link to="/about" class="nav-link">About</router-link>
            </li> -->
        </ul>
        <BleConnector />
        <div class="d-none d-lg-block"><Account /></div>
    </nav>
    <div class="container-fluid">
        <router-view v-show="true || this.$store.state.ble.receiving" />
    </div>
    <div ref="app-overlay" id="app-overlay">Loading...</div>
</template>
<script>
// @ is an alias to /src
import BleConnector from '@/components/BleConnector.vue'
import Account from '@/components/Account.vue'

export default {
    components: {
        BleConnector, Account
    },
    methods: {
        refresh(){
            location.reload();
        }
    },
    created: function(){
        this.$store.commit('updateUserData');
    }
}
</script>
<style lang="scss">
    @import "./scss/custom-bootstrap";
    @import "./assets/icofont/icofont.css";
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;700&display=swap');

    #app-overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(#0000FF, 1);
        color: white;
        font-size: 3vw;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999999;
        display: none;
    }
</style>