import { createStore } from 'vuex'
import { getField, updateField } from 'vuex-map-fields';

export default createStore({
    state: {
        safe_mode: 1,
        calculated_value: 0,
        calculated_value_timestamp: 0,
        calculated_value_formatted: 0,
        calculated_value_averged: 0,
        converted_value: 0,
        converted_value_formatted: 0,
        display_value: 0,
        display_value_formatted: 0,
        ble: {
            log: '',
            device_name: '',
            receiving: false,
            device: null,
            server: null,
            service: null,
            raw_value: 0,
            raw_value_timestamp: 0,
            buffer: null,
            w_chr: null, //write characteristic
            r_chr: null, //read characteristic
            freq: -1, // 19 => 5Hz
            dataviewOnceListener: function(){} //se ci sta mandando qualche segnale diverso dalla lettura del sensore lo mettiamo qua
        },
        sensor_reset: {},
        sensor: {
            id: null,
            fs: null, //fondo scala
            um: null, //unità di misura
            overloaded: false,
            um_map: {
                1: 'daN',
                2: 'N',
                3: 'kN',
                4: 'MN',
                5: 'gf',
                6: 'kgf',
                7: 'lbf',
                8: 'um',
                9: 'mm',
                10: 'cm',
                11: 'm',
                12: 'mm/mm',
                13: 'um/m',
                14: 'MPa'
            },
            state: {
                peak: false,
                hold: false,
                tare: 0
            },
            calibration: {
                positive_full: 269470,
                zero: 2288,
                negative_full: -264162
            },
            linearization: [
                // [ perc_fondo_scala, raw_value ]
                [ 0, 0],
                [ '', '' ],
                [ '', '' ],
                [ '', '' ],
                [ '', '' ],
                [ '', '' ],
            ],
            linearization_neg: [
                // [ perc_fondo_scala, raw_value ]
                [ 0, 0],
                [ '', '' ],
                [ '', '' ],
                [ '', '' ],
                [ '', '' ],
                [ '', '' ],
            ],
            interpolation: {
                P: 0,
                Q: 1,
                R: 0,
                S: 0
            },
            interpolation_neg: {
                P: 0,
                Q: 1,
                R: 0,
                S: 0
            },
            custom_conversion:{
                um: null,
                formula: null,
                resolution: 3
            }
        },
        display: {
            on_hold: false,
            hold_value: 0,
            on_peak: false,
            on_peak_plus: false,
            on_peak_minus: false,
            peak_value: 0,
            peak_plus_value: 0,
            peak_minus_value: 0,
            on_tare: false,
            tare_value: 0,
            tare_raw_value: 0,
            direction: 1, // 1 direzione positiva, -1 direzione negativa (es. trazione compressione)
            resolution_points: 3,
            filter_lenght: 5,
            filter_buffer: [],
            display_size: 3
        },
        testing: {
            name: '',
            recording: false,
            start_listening: false,
            end_listening: false,
            record_timestamp_start: 0,
            latest_record_timestamp: 0,
            record_digits_start: 0,
            record_peak: 0,
            record_peak_minus: 0,
            record_peak_calculated: 0,
            record_peak_converted: 0,
            testing_conditions: {
                start: {
                    when: 1,
                    target: null,
                    compare: null,
                    value: null
                },
                end: {
                    when: 1,
                    target: null,
                    compare: null,
                    value: null,
                    after_ms: 0,
                    peak_perc: 0
                }
            },
            sensor_state: {},
            display_state: {},
            record_length: 0,
            records: [],
        },
        test_details: null,
        chart: {
            labels: [],
            data: [],
            latest_timestamp: 0
        },
        user: {
            logged: false,
            token: null,
            data: [],
            sensor_library: {},
            test_library: {},
        },
        report_settings: {
            logo_url: false,
            preview: true,
            header_data: '',
            footer_data: ''
        }
    },
    getters: {
        // Add the `getField` getter to the
        // `getters` of your Vuex store instance.
        getField,
        sensorData: state => {
            return state.sensor;
        }
    },
    mutations: {
        setBleProperty(state, prop){ // es. prop: { key: 'receiving', value: true }
            state.ble[prop.key] = prop.value;
            // console.log(prop.key)
        },
        dataviewOnceListener(state, callback){
            state.ble['dataviewOnceListener'] = callback;
        },
        dataviewRunListener(state, dataView){
            console.log('inside dataviewRunListener', dataView, state);
            if (typeof state.ble['dataviewOnceListener'] === 'function') {
                state.ble['dataviewOnceListener'](dataView);
            }
            state.ble['dataviewOnceListener'] = function(){};
        },
        setSensor(state, sensorData){
            state.sensor = sensorData;
        },
        setSensorReset(state, sensorData){
            state.sensor_reset = sensorData;
        },
        setSensorProperty(state, prop){
            state.sensor[prop.key] = prop.value;
        },
        updateSensorLibrary(state){
            let sensor_library = JSON.parse(localStorage.getItem('sensor_library'));
            if(sensor_library){
                state.user.sensor_library = sensor_library;
            }
        },
        setDisplayValue(state, value){
            state.display_value = value;
        },
        setDisplayValueFormatted(state, value){
            state.display_value_formatted = value;
        },
        setDisplay(state, display){
            state.display = display;
        },
        setDisplayProperty(state, prop){
            state.display[prop.key] = prop.value;
        },
        setTestingProperty(state, prop){
            state.testing[prop.key] = prop.value;
        },
        setChartProperty(state, prop){
            state.chart[prop.key] = prop.value;
        },
        setTestingRecord(state){
            state.testing.records.push([1,2])
        },
        setCalculatedVal(state, val){
            state.calculated_value = val;
        },
        setCalculatedValFormatted(state, val){
            state.calculated_value_formatted = val;
        },
        setConvertedVal(state, val){
            state.converted_value = val;
        },
        setConvertedValFormatted(state, val){
            state.converted_value_formatted = val;
        },
        setUserProperty(state, prop){
            state.user[prop.key] = prop.value;
        },
        updateUserData(state){
            let user = JSON.parse(localStorage.getItem('user'));
            if(user){
                state.user = user.user;
                state.user.token = user.token;
                state.user.logged = true;
            }else{
                state.user = [];
                state.user.false = true;
            }
            // console.log(state.user);
        },
        updateTestLibrary(state){
            let test_library = JSON.parse(localStorage.getItem('test_library'));
            if(test_library){
                state.user.test_library = test_library;
            }
        },
        setTestingDetails(state, test_details){
            state.test_details = test_details;
        },
        setSafeMode(state, is_safe){
            state.safe_mode = is_safe;
        },
        updateReportSettings(state, report_settings){
            state.report_settings = report_settings;
        },
        // Add the `updateField` mutation to the
        // `mutations` of your Vuex store instance.
        updateField,
    },
    actions: {},
    modules: {}
})